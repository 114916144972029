import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../../components/service-hero";
import OurServicesSection from "../../components/our-services-section";
import Reviews from "../../components/reviews";
import ImageSideContainer from "../../components/image-side-container";
import { Button } from "react-bootstrap";
import MotForm from "../../components/mot-form";
import { checkPropertiesForNull } from "../../../utils";
import { SafeHtmlParser } from "../../components/safeHtmlParser";

const AllowWheelRefurbishmentPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
				reviewAuthor
				reviewDate
				reviewContent
			}
			schemaLogo: file(relativePath: { eq: "icon.png" }) {
				publicURL
			}

			pageData: wpPage(slug: { eq: "alloy-wheel-refurbishment" }) {
				slug
				alloyRefurbishmentFields {
					alloyRefurbishmentBanner {
						alloyRefurbishmentBannerHeading
						alloyRefurbishmentBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					alloyRefurbishmentContentSection {
						alloyRefurbishmentContentHeading
						alloyRefurbishmentContentText
						alloyRefurbishmentContentButton {
							title
							target
							url
						}
					}
					alloyRefurbishmentForm {
						alloyRefurbishmentFormHeading
					}
					alloyRefurbishmentLeftImageRightContent {
						alloyRefurbishmentRightContentHeading
						alloyRefurbishmentRightContentText
						alloyRefurbishmentRightContentButton {
							title
							target
							url
						}
						alloyRefurbishmentLeftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					alloyRefurbishmentReviews {
						alloyRefurbishmentReviewsHeading
					}
					alloyRefurbishmentServicesCards {
						alloyRefurbishmentServicesCardsHeading
						alloyRefurbishmentServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const {
		pageData: { seoFields, alloyRefurbishmentFields, slug },
	} = data;

	const {
		alloyRefurbishmentBanner,
		alloyRefurbishmentContentSection,
		alloyRefurbishmentForm,
		alloyRefurbishmentLeftImageRightContent,
		alloyRefurbishmentReviews,
		alloyRefurbishmentServicesCards,
	} = alloyRefurbishmentFields;
	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
		mpn: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		sku: `https://www.ecfcarcare.co.uk/what-we-do/${slug}`,
		description: `${seoFields?.metaDescription}`,
		logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
		name: "ECF Car Care",
		url: "https://www.ecfcarcare.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.ecfcarcare.co.uk${data.schemaLogo.publicURL}`,
			image: `https://www.ecfcarcare.co.uk${seoFields?.image?.node?.localFile.publicURL}`,
			name: "ECF Car Care",
			url: "https://www.ecfcarcare.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "5",
			bestRating: "5",
			worstRating: "1",
		},

		review: {
			"@type": "Review",
			url: "https://www.ecfcarcare.co.uk/reviews",
			datePublished: `${data.reviews.reviewDate}`,
			reviewBody: `${data.reviews.reviewContent}`,
			author: {
				"@type": "Person",
				name: `${data.reviews.reviewAuthor}`,
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "ECF Car Care",
				sameAs: "https://www.ecfcarcare.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "What We Do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Alloy Wheel Refurbishment",
				item: {
					url: `${siteUrl}/what-we-do/alloy-wheel-refurbishment`,
					id: `${siteUrl}/what-we-do/alloy-wheel-refurbishment`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{alloyRefurbishmentBanner && (
					<section>
						<ServiceHero
							title={alloyRefurbishmentBanner.alloyRefurbishmentBannerHeading}
							backgroundImage={
								alloyRefurbishmentBanner.alloyRefurbishmentBannerBackgroundImage
									.node?.localFile.childImageSharp.gatsbyImageData
							}
							imgAlt={
								alloyRefurbishmentBanner.alloyRefurbishmentBannerBackgroundImage
									.node?.altText
							}
						/>
					</section>
				)}

				{alloyRefurbishmentContentSection &&
					!checkPropertiesForNull(alloyRefurbishmentContentSection, [
						"alloyRefurbishmentContentHeading",
					]) && (
						<section className="py-5  py-lg-8 position-relative">
							<Container>
								<Row>
									<Col className="position-relative">
										<h2 className="text-center pb-5">
											{
												alloyRefurbishmentContentSection.alloyRefurbishmentContentHeading
											}
										</h2>
										<SafeHtmlParser
											htmlContent={
												alloyRefurbishmentContentSection?.alloyRefurbishmentContentText
											}
										/>
										{alloyRefurbishmentContentSection.alloyRefurbishmentContentButton &&
											alloyRefurbishmentContentSection
												.alloyRefurbishmentContentButton.url && (
												<Row>
													<Col className="text-center text-md-start">
														<Button
															style={{ borderRadius: "0px" }}
															variant="secondary"
															as={Link}
															to={
																alloyRefurbishmentContentSection
																	.alloyRefurbishmentContentButton.url
															}
															className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
															target={
																alloyRefurbishmentContentSection
																	.alloyRefurbishmentContentButton.target ??
																"_self"
															}
														>
															{
																alloyRefurbishmentContentSection
																	.alloyRefurbishmentContentButton.title
															}
														</Button>
													</Col>
												</Row>
											)}
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{alloyRefurbishmentLeftImageRightContent &&
					!checkPropertiesForNull(alloyRefurbishmentLeftImageRightContent, [
						"alloyRefurbishmentRightContentHeading",
					]) && (
						<div className="pb-5 pb-lg-8">
							<ImageSideContainer
								image={
									alloyRefurbishmentLeftImageRightContent
										.alloyRefurbishmentLeftImage.node?.localFile.childImageSharp
										.gatsbyImageData
								}
								imageAlt={
									alloyRefurbishmentLeftImageRightContent
										.alloyRefurbishmentLeftImage.node?.altText
								}
								title={
									alloyRefurbishmentLeftImageRightContent.alloyRefurbishmentRightContentHeading
								}
								order="first"
								imagePadding=" pb-5 pb-lg-0    "
								text={
									alloyRefurbishmentLeftImageRightContent.alloyRefurbishmentRightContentText
								}
							/>
						</div>
					)}

				<section>
					<Reviews
						title={alloyRefurbishmentReviews?.alloyRefurbishmentReviewsHeading}
					/>
				</section>
				<section id="book" className="pb-5  pt-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={6}>
								<h2 className="text-center pb-4">
									{alloyRefurbishmentForm.alloyRefurbishmentFormHeading ||
										"BOOK YOUR APPOINTMENT"}
								</h2>
								<div
									style={{ background: "#F9F9F9", borderRadius: "18px" }}
									className="p-md-5 p-4"
								>
									<MotForm page="Alloy Wheel Refurbishment" />
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				{/* {alloyRefurbishmentServicesCards &&
          !checkPropertiesForNull(alloyRefurbishmentServicesCards, [
            "alloyRefurbishmentServicesCardsHeading",
          ]) && (
            <OurServicesSection
              title={
                alloyRefurbishmentServicesCards.alloyRefurbishmentServicesCardsHeading
              }
              services={
                alloyRefurbishmentServicesCards.alloyRefurbishmentServicesCards
                  ?.nodes
              }
            />
          )} */}
			</Layout>
		</>
	);
};

export default AllowWheelRefurbishmentPage;
